:root {
  --primary-color: #e86e41;
  --primary-bg: #e86e41;
  --border-color: #f7865db2;
  --shadow: 0px 0px 48px 0px #ff7748;
}
.highlight {
  color: var(--primary-color);
}
body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.app {
  margin: auto;
  /*max-width: 1200px;*/
}
button {
  cursor: pointer;
}
.btn {
  cursor: pointer;
}
.navbar {
  background: linear-gradient(180deg, rgba(247, 134, 93) 0%, #fff1ec 90%);
  padding-bottom: 3.43rem;

  .content {
    /*width: 100%;*/
    padding: 1.64rem 3.6rem;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .login-buttons {
    display: flex;
    .btn {
      border: none;
      background: white;
      color: var(--primary-bg);
      font-size: 2rem;
      word-break: keep-all;
    }
    .btn-register {
      word-break: keep-all;
      background: var(--primary-color);
      color: white;
      margin-left: 1rem;
      padding: 0 3rem;
      height: 2.86rem;
      font-size: 2rem;
      border-radius: 0.71rem;
      border: none;
    }
  }
}

.hero-section {
  width: 68%;
  margin: auto;
  text-align: center;
  padding: 2rem;
  background: white;
  position: relative;
  max-width: 1200px;
  .imgBox {
    outline: none !important;
  }
  h2 {
    font-size: 3.43rem;
    line-height: 5rem;
    color: #4e5969;
    margin-bottom: 1rem;
    font-weight: 700;
    text-align: center;
  }

  p {
    font-size: 1.14rem;
    color: #374266;
    margin: 2rem auto 2.8rem;
  }

  .btn {
    width: 18.29rem;
    height: 3.43rem;
    border: none;
    background: var(--primary-bg);
    color: white;
    font-size: 1.43rem;
    cursor: pointer;
    border-radius: 10px;
    z-index: 9999;
    position: relative;
  }
  img {
    width: 100%;
  }
  .slider {
    margin-top: -13rem;
  }
}

.storyline-container {
  margin-top: 2rem;
  position: relative;
  span {
    display: block;
    margin-bottom: 1rem;
  }
}

.slick-slide img {
  width: 100%;
  height: auto;
}

.ai-tools {
  text-align: center;
  position: relative;
  width: 60%;
  margin: 5rem auto 0;
  .content {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;
  }

  h2 {
    font-weight: 400;
    font-size: 2.57rem;
    color: #262626;
    margin: 0 auto 4.3rem;
    line-height: 3rem;
  }

  .menu {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 0.5rem;

    .item {
      font-size: 1.43rem;
      color: #4e5969;
      text-align: left;
      width: 200px;
      padding: 1rem;
      border-radius: 5px;
      cursor: pointer;
    }

    .item:hover {
      background: #e5e6eb;
      color: var(--primary-color);
    }
  }

  .customVideo,
  video {
    width: 43rem !important;
    height: 29rem !important;
  }
  .btn {
    width: 18.29rem;
    height: 2.86rem;
    line-height: 2.86rem;
    font-size: 1rem;
    color: #ffffff;
    background: #e86e41;
    border-radius: 1.07rem;
    border: 0px;
    position: absolute;
    bottom: -5rem;
    right: 2rem;
  }
}

.process-steps {
  text-align: center;
  margin: 10rem auto 2rem;
  button {
    margin-top: 1rem;
    padding: 0.8rem 2rem;
    border: none;
    background: var(--primary-bg);
    color: white;
  }
  .step-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem 0;
  }

  .step-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 6px;
    flex-direction: column;
    .icon {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin-bottom: 0.3em;
    }

    .text {
      width: 5rem;
      word-break: keep-all;
    }
  }

  .connector {
    width: 50px;
    height: 2px;
    background-color: var(--primary-color);
    margin-top: -1.5rem;
  }

  h2 {
    font-size: 2.57rem;
    color: #262626;
    line-height: 3.29rem;
    font-weight: 400;
  }

  .steps {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .step {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 50%;
    background: white;
  }

  .modules {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
  }

  .module {
    text-align: left;
    width: 17.14rem;
    /*padding: 1rem;*/
    border: 1px solid #ddd;
    border-radius: 20px;
    box-shadow: 0rem 0rem 0.31rem 0rem rgba(0, 0, 0, 0.25);
    .content {
      padding: 1rem;
      h3 {
        color: var(--primary-color);
      }
      p {
        color: #4e5969;
      }
    }

    img {
      width: 100%;
      height: auto;
    }

    a {
      display: inline-block;
      text-decoration: none;
      color: var(--primary-color);
      float: right;
      margin: 2rem 1rem;
    }
  }
  button {
    margin: 2.86rem 0;
    width: 18.29rem;
    height: 2.86rem;
    background: #e86e41;
    border-radius: 1rem;
  }
}

.detection-rate {
  text-align: center;

  h2 {
    font-weight: 400;
    font-size: 2.57rem;
    color: #262626;
    line-height: 4.29rem;
    margin-bottom: 2rem;
  }

  .images {
    display: flex;
    justify-content: center;
  }

  .image {
    margin: 0 -4rem;
    /*overflow: hidden;*/
    img {
      width: 100%;
      height: auto;
      transition: transform 0.3s;
      position: relative;
    }
    img:hover {
      z-index: 100;
      width: 120%;
      margin-top: -10%;
    }
    .img2 {
      z-index: 99;
      width: 120%;
      margin-top: -10%;
      /*transform: scale(1.1);*/
    }
    /* 添加放大类 */
    .zoomed {
      width: 120%;
      margin-top: -10%;
    }
    /* 添加恢复类 */
    .normal {
      width: 100% !important;
      margin-top: 0;
    }
  }

  .texts {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8rem;
    margin-top: 2rem;
    .text {
      display: flex;
      align-items: center;
      .icon {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
    p {
      color: var(--primary-color);
      margin: 0 1rem;
      font-size: 1.6rem;
    }
  }

  .description {
    width: 55%;
    margin: 3rem auto;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0px 0px 4.4px 0px #00000040;
    background: #fff1ec;
    text-align: left;
    color: #86909c;
  }
}

.testimonials {
  text-align: center;
  padding: 2rem 0 3.64rem;
  background: url("assets/images/testimonials-bg.png");
  .description {
    width: 35%;
    margin: auto;
    color: #86909c;
    background: #fff;
    padding: 0.6rem;
  }
  h2 {
    font-weight: 400;
    font-size: 2.57rem;
    color: #262626;
    line-height: 4.29rem;
    margin-bottom: 2rem;
    margin-top: 0rem;
  }

  .scrolling-testimonials {
    /*display: flex;*/
    color: #86909c;
    display: -webkit-box;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    margin-top: 2rem;
    /*white-space: nowrap;*/
    animation: scrollText 10s linear infinite;
    .name {
      font-size: 1.4rem;
      color: #4e5969;
    }
  }

  .testimonial {
    width: 25rem;
    padding: 1rem;
    border-radius: 15px;
    text-align: left;
    margin-right: 20px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
  }

  .scrolling-testimonials::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  @keyframes scrollText {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

.case-video {
  text-align: center;
  h2 {
    font-size: 2.57rem;
    font-weight: 400;
    margin: 3.4rem auto 0.6rem;
    line-height: 3rem;
  }
  .videoWarp {
    margin: 4rem auto;
    .customVideo {
      width: 54rem;
      height: 36rem;
    }
  }
}

.packages {
  text-align: center;
  width: 71%;
  margin: auto;
  h2 {
    font-size: 2.57rem;
    font-weight: 400;
  }
  .desc {
    color: #86909c;
    margin: 0;
  }

  .package-list {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }

  .package {
    text-align: left;
    width: 20rem;
    padding: 1rem 2rem;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    .price {
      font-size: 1.5rem;
      margin: 1.64rem 0;
    }
    .pricing {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      .highlighted {
        font-weight: bold;
      }
      span:nth-last-child(1) {
        border-right: 1px solid #ddd;
      }
      .date {
        background: aliceblue;
        padding: 0.5rem;
      }
      .date-highlight {
        background: var(--primary-color);
        color: white;
      }
    }

    button {
      width: 18.29rem;
      height: 2.86rem;
      line-height: 1.86rem;
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      background: #ffffff;
      font-size: 1.43rem;
      cursor: pointer;
      width: 100%;
    }
    button:hover {
      background: var(--primary-color);
      color: white;
    }
    .service {
      .service-title {
        color: #4e5969;
      }
      .item {
        display: flex;
        align-items: center;
        gap: 0.2rem;
      }
    }
  }
  .package:hover {
    border: 1px solid var(--primary-color);
    box-shadow: 0px 0px 4.4px 0px var(--primary-color);
  }
}

.faqs {
  text-align: center;
  width: 62%;
  margin: 0 auto;
  h2 {
    font-size: 2.57rem;
    font-weight: 400;
    margin-bottom: 1rem;
    margin-top: 4rem;
    line-height: 3rem;
  }

  .faq-item {
    margin-bottom: 1rem;
  }

  .question {
    cursor: pointer;
    font-size: 1.3rem;
    color: #262626;
    padding: 1rem;
    border-bottom: 1px solid #c9cdd4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.3rem;
    text-align: left;
    padding-left: 4.3rem;
  }

  .answer {
    font-size: 1.1rem;
    padding: 1rem;
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background: #fff;
    text-align: left;
    min-height: 5rem;
  }
  a {
    color: var(--primary-color);
    text-decoration: none;
    font-size: 1.43rem;
    color: #e86e41;
    margin: 2rem;
    display: block;
  }
}

.footer {
  background: url("assets/images/testimonials-bg.png");
  color: white;
  padding: 2rem;
  position: relative;

  .footer-row {
    display: flex;
    justify-content: space-between;
  }

  .footer-top {
    color: var(--primary-color);
    border-bottom: 1px solid #fff;
    display: flex;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }
  .footer-bottom {
    margin-top: 2rem;
    .title {
      color: var(--primary-color);
    }
  }
  .logo {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    width: 12rem;
  }
  .footer-links {
    display: flex;
    gap: 2rem;
    span {
      color: var(--primary-color);
      background: #fff;
      padding: 0 0.5rem;
      border-radius: 5px;
      cursor: pointer;
    }
    span:hover {
      background: var(--primary-color);
      color: white;
    }
  }
}
.mobile {
  h2 {
    font-size: 1.8rem !important;
  }
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 2.4rem;

    .content {
      padding: 1rem 2rem;
    }

    .logo {
      width: 8rem;
    }

    .login-buttons {
      display: flex;
      .btn {
        font-size: 1rem !important;
      }
      .btn-register {
        font-size: 1rem !important;
      }
    }
  }

  .hero-section {
    h2 {
      line-height: 2.4rem;
    }
    p {
      font-size: 0.9rem;
    }

    .slider {
      margin-top: -4rem;
      width: 100%;
    }
  }
  .step-bar {
    display: none !important;
    flex-direction: column;
    align-items: center;
    margin: 0 !important;
    .step-icon {
      margin-bottom: 10px;
    }
    .connector {
      width: 2px;
      height: 30px;
      margin: 0 0 0.5rem;
    }
    .icon {
      width: 18px;
      height: 18px;
      div {
        font-size: 12px;
      }
    }
  }
  .process-steps .modules,
  .detection-rate .images,
  .packages .package-list {
    flex-direction: column;
    align-items: center;
  }
  .detection-rate {
    .images {
      width: 100%;
      overflow: hidden;
    }
    .image {
      width: 100% !important;
    }
    .texts {
      gap: 2rem;
      .icon {
        width: 1.2rem;
        height: 1.2rem;
      }
      p {
        font-size: 1rem;
      }
    }

    .img2 {
      z-index: 99;
      width: 100% !important;
      margin-top: -10rem !important;
      margin-right: -3rem;
    }
    .img3 {
      margin-top: -10rem !important;
      margin-left: -7rem;
    }
    .zoomed {
      width: 100%;
      margin-top: 0;
    }
  }
  .ai-tools {
    width: 80%;
    .content {
      flex-direction: column;
      align-items: center;
    }
    .menu {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .item {
      font-size: 1rem;
      width: 40% !important;
      padding: 0.2rem 0.6rem !important;
      border: 1px solid #e1e1e1;
      text-align: center !important;
    }
    video {
      width: 100%;
      object-fit: contain;
    }
  }
  .process-steps {
    .module {
      width: 80%;
    }
  }
  .detection-rate {
    .description {
      width: 77%;
    }
  }
  .testimonials {
    padding: 1rem !important;
    .description {
      width: 80%;
    }
  }
  .case-video {
    .videoWarp {
      width: 96%;
      margin: auto;
    }
  }
  .customVideo,
  video {
    width: 100% !important;
    height: 100% !important;
  }
  .faqs {
    width: calc(100% - 4rem);
    padding: 2rem;
    .question {
      padding: 1rem;
    }
  }
  .footer {
    .footer-row {
      flex-direction: column;
      align-items: center;
    }

    .footer-col {
      margin-bottom: 2rem;
    }
    .footer-top {
      img {
        width: 4rem;
      }
    }
    .logo {
      width: 10rem;
    }
    .footer-links {
      flex-wrap: wrap;
      gap: 0.8rem;
      margin-bottom: 2rem;
    }
  }
  .question {
    img {
      width: 1.5rem;
      margin-left: 1rem;
    }
  }
}
/*@media (max-width: 768px) {*/
/*  h2 {*/
/*    font-size: 1.8rem !important;*/
/*  }*/
/*  .navbar {*/
/*    flex-direction: column;*/
/*    align-items: flex-start;*/
/*    .logo {*/
/*      width: 8rem;*/
/*    }*/
/*  }*/
/*  .hero-section {*/
/*    p {*/
/*      font-size: .9rem;*/
/*    }*/

/*    .slider {*/
/*      margin-top: -4rem;*/
/*      width: 100%;*/
/*    }*/
/*  }*/
/*  .step-bar {*/
/*    display: none !important;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    margin: 0 !important;*/
/*    .step-icon {*/
/*      margin-bottom: 10px;*/
/*    }*/
/*    .connector {*/
/*      width: 2px;*/
/*      height: 30px;*/
/*      margin: 0 0 .5rem;*/
/*    }*/
/*    .icon {*/
/*      width: 18px;*/
/*      height: 18px;*/
/*      div {*/
/*        font-size: 12px;*/
/*      }*/
/*    }*/
/*  }*/
/*  .process-steps .modules,*/
/*  .detection-rate .images,*/
/*  .packages .package-list {*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*  }*/
/*  .detection-rate {*/
/*    .image {*/
/*      width: 100%;*/
/*    }*/
/*    .texts {*/
/*      gap: 2rem;*/
/*    }*/

/*    .img2 {*/
/*      z-index: 99;*/
/*      width: 100% !important;*/
/*      margin-top: -10rem !important;*/
/*      margin-right: -3rem;*/
/*    }*/
/*    .img3 {*/
/*      margin-top: -10rem !important;*/
/*      margin-left: -7rem;*/
/*    }*/
/*    .zoomed {*/
/*      width: 100%;*/
/*      margin-top: 0;*/
/*    }*/
/*  }*/
/*  .ai-tools {*/
/*    .content {*/
/*      flex-direction: column;*/
/*      align-items: center;*/
/*    }*/
/*    .menu {*/
/*      flex-direction: row;*/
/*      flex-wrap: wrap;*/
/*      align-items: center;*/
/*      justify-content: center;*/
/*    }*/
/*    .item {*/
/*      width: 40% !important;*/
/*      padding: .2rem .6rem !important;*/
/*      border: 1px solid #E1E1E1;*/
/*      text-align: center !important;*/
/*    }*/
/*    img {*/
/*      width: 90%;*/
/*      object-fit: contain;*/
/*    }*/
/*  }*/
/*  .testimonials {*/
/*    padding: 1rem !important;*/
/*  }*/
/*  .footer {*/
/*    .footer-row {*/
/*      flex-direction: column;*/
/*      align-items: center;*/
/*    }*/

/*    .footer-col {*/
/*      margin-bottom: 2rem;*/
/*    }*/
/*    .footer-top {*/
/*      img {*/
/*        width: 4rem;*/
/*      }*/
/*    }*/
/*    .logo {*/
/*      width: 10rem;*/
/*    }*/
/*    .footer-links {*/
/*      flex-wrap: wrap;*/
/*      gap: .8rem;*/
/*      margin-bottom: 2rem*/
/*    }*/
/*  }*/
/*  .question {*/
/*    img {*/
/*      width: 1.5rem;*/
/*      margin-left: 1rem;*/
/*    }*/
/*  }*/
/*}*/

.customVideo {
  position: relative;
  width: 640px;
  height: 360px;
  margin: auto;
  &:hover {
    .btnBox {
      display: block;
      position: absolute;
      top: 4px;
      left: 0;
      width: inherit;
      height: inherit;
      background: rgba(0, 0, 0, 0.5);
      text-align: center;
      .anticon {
        color: #ff8558;
        font-size: 5rem;
        cursor: pointer;
        top: calc(50% - 2.5rem);
        position: absolute;
        left: calc(50% - 2.5rem);
      }
    }
  }

  video {
    width: 100%;
    height: 100%;
  }
  .btnBox {
    display: none;
  }
}
